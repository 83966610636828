import React from 'react';
import { Form, Select, Radio, ConfigProvider } from 'antd';
import { connect } from 'react-redux';
import { fetchProvince, fetchCity, fetchEducation, fetchFunds } from '../../actions/AdditionalForm';
import CustomizeNotFound from '../CustomizeNotFound';
import { isArray } from 'lodash';

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class DinasKabupaten extends React.Component {
  state = {
    provinceList: [],
    cityList: [],
    onLoadProvince: true,
    onLoadCity: false,
    isCityReady: true,
    onLoadEducation: true,
    selectedProvince: '',
    educationList: [],
    fundList: [],
    onLoadFunds: true,
    jenjangTypeValue: false,
    sumberDanaTypeValue: false,
    isUserEdit: false,
    editProvinceVal: null,
  }


  componentDidMount(){
    // const {onLoadProvince, onLoadEducation, onLoadFunds} = this.state
    this.props.fetchProvince(this.props.auth.id_token || process.env.REACT_APP_API_KEY, 'limit=34')
    .then((result) => {
      const ascendingResult = result.items.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      this.setState({
        onLoadProvince: false,
        provinceList: ascendingResult
      })
    }).catch((error) => {
      console.log(error)
      this.setState({
        onLoadProvince: false,
        provinceList: []
      })
    })

    this.props.fetchEducation(this.props.auth.id_token || process.env.REACT_APP_API_KEY)
    .then((result) => {
      this.setState({
        onLoadEducation: false,
        educationList: result.items
      })
    }).catch((error) => {
      console.log(error)
      this.setState({
        onLoadEducation: false,
        educationList: []
      })
    })

    this.props.fetchFunds(this.props.auth.id_token || process.env.REACT_APP_API_KEY)
    .then((result) => {
      this.setState({
        onLoadFunds: false,
        fundList: result.items
      })
    }).catch((error) => {
      console.log(error)
      this.setState({
        onLoadFunds: false,
        fundList: []
      })
    })
  }

  componentDidUpdate(prevProps, prevState){
    const { isEdit } = this.props

    if (prevState?.onLoadProvince !== this.state.onLoadProvince && isEdit) {
      this.handleEditForm()
    }
  }

  handleEditForm = () => {
    const { editValue } = this.props
    
    setTimeout(() => {
      this.handleChangeProvince(isArray(editValue?.province) ? editValue?.province[0] : editValue?.province, true)
      this.handleChangeEducation(editValue?.education)

      if (editValue?.education === true) {
        this.onChangeJenjangType(true)
      } else {
        this.handleChangeEducation(editValue?.education)
      }

      if (editValue?.fund === true) {
        this.onChangeSumberDanaType(true)
      } else {
        this.handleChangeSource(editValue?.fund)
      }
    }, 500)
  }

  handleChangeProvince = (value, isEdit) => {
    const { editValue } = this.props
    let newProvinceVal
    let selectedCity = isEdit !== true && this.props.formIdentity.current.getFieldValue('city')
  
    if (selectedCity) {
      this.props.formIdentity.current.setFieldsValue({
        city: undefined,
      })
    }
    
    if(isEdit === true){
      newProvinceVal = (this.state.provinceList || []).find(item => item.name === value)
    } else {
      newProvinceVal = (this.state.provinceList || []).find(item => item.id === value)
    }

    if (newProvinceVal) {
      newProvinceVal = {
        id: newProvinceVal.id,
        kd_prop: newProvinceVal.kd_prop
      }
    }
    
    const limit = '514'
    this.setState({
      selectedProvince: isEdit === true ? newProvinceVal?.id : value,
      onLoadCity: true,
      isCityReady: true,
    })
    this.props.sendProvince(newProvinceVal)
  
    if (value) {
      this.props.fetchCity(this.props.auth.id_token || process.env.REACT_APP_API_KEY, `limit=${limit}&province_ids=${isEdit === true ? newProvinceVal?.id : value}`)
      .then((result) => {   
        const ascendingResult = result.items.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })   
        this.setState({
          onLoadCity: false,
          isCityReady: false,
          cityList: ascendingResult
        })
        if(isEdit === true){
          this.handleChangeCity(isArray(editValue?.city) ? editValue?.city[0] : editValue?.city, isEdit)
        }
      }).catch((error) => {
        this.setState({
          onLoadCity: false,
          isCityReady: false,
          cityList: []
        })
      })
    } else {
      this.setState({
        onLoadCity: false,
        isCityReady: true,
        cityList: []
      })
    } 
  }

  handleChangeCity = (value, isEdit) => {
    let newCityVal
    if(isEdit === true){
      newCityVal = (this.state.cityList || []).find(item => item.name === value)
    } else {
      newCityVal = (this.state.cityList || []).find(item => item.id === value)
    }

    if (newCityVal) {
      newCityVal = {
        id: newCityVal.id,
        kd_kabkota: newCityVal.kd_kab_kota
      }
    }
    this.props.sendCity(newCityVal) //send value to parent
  }

  handleChangeEducation = (value) => {
    this.props.sendEducation(value)
  }

  handleChangeSource = (value) => {
    let newFundsVal
    let arrFund = []

    if (value.length > 1) {
      value.forEach(id => {
        newFundsVal = (this.state.fundList || []).find(item => item.kd_sumber_dana === id)
        newFundsVal && arrFund.push({kd_sumber_dana: newFundsVal.kd_sumber_dana, fund_source_name: newFundsVal.name})
      })
    } else if (value.length === 1) {
      newFundsVal = (this.state.fundList || []).find(item => item.kd_sumber_dana === value[0])
      newFundsVal ? arrFund.push({kd_sumber_dana: newFundsVal.kd_sumber_dana, fund_source_name: newFundsVal.name}) : newFundsVal = []
    } else {
      newFundsVal = ''
      arrFund = []
    }
    this.props.sendSource(arrFund) //send value to parent
  }

  onChangeJenjangType = (e) => {
    this.setState({ jenjangTypeValue: e })

    if (e) {
      this.props.formIdentity.current.setFieldsValue({
        education_stage: undefined,
        is_all_education_stage: true
      })
      this.props.sendEducation([]) //send empty array if jenjang all
    }
  }

  onChangeSumberDanaType = (e) => {
    this.setState({ sumberDanaTypeValue: e })

    if (e) {
      this.props.formIdentity.current.setFieldsValue({
        fund_sources: undefined,
        is_all_fund_source: true
      })
      this.props.sendSource([]) //send empty array if source all
    }
  }


  render(){
    const {
      provinceList, onLoadProvince, cityList, onLoadCity,
      educationList, onLoadEducation, isCityReady, fundList,
      onLoadFunds, jenjangTypeValue, sumberDanaTypeValue
    } = this.state

    return(
      <React.Fragment>
        <ConfigProvider renderEmpty={CustomizeNotFound}>
          <Form.Item
            label="Wilayah"
            name="province"
            rules={[{ required: true, message: "Wajib diisi" }]}
          >
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder="Pilih provinsi"
              onChange={this.handleChangeProvince}
              loading={onLoadProvince}
              disabled={onLoadProvince}
            >
              {(provinceList || []).map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item
            label="Kota"
            name="city"
            rules={[{ required: true, message: "Wajib diisi" }]}
          >
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder="Pilih kabupaten/kota"
              onChange={this.handleChangeCity}
              disabled={isCityReady}
              loading={onLoadCity}
            >
              {(cityList || []).map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
            </Select>
          </Form.Item>

          <Form.Item
            label="Jenjang Pendidikan"
            name="is_all_education_stage"
            initialValue={false}
            className={`${!jenjangTypeValue ? 'mb-2' : ''}`}
          >
            <Radio.Group onChange={(e) => this.onChangeJenjangType(e.target.value)} value={jenjangTypeValue}>
              <Radio value={false}>Pilih Sebagian</Radio>
              <Radio value={true}>Pilih Semua</Radio>
            </Radio.Group>
          </Form.Item>

          {!jenjangTypeValue &&
            <Form.Item
              {...tailFormItemLayout}
              name="education_stage"
              rules={[{ required: true, message: "Wajib diisi" }]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Pilih jenjang pendidikan"
                onChange={this.handleChangeEducation}
                loading={onLoadEducation}
                disabled={onLoadEducation}
              >
              {(educationList || []).map((item, index) => <Select.Option key={index} value={item.stage}>{item.stage}</Select.Option>)}
              </Select>
            </Form.Item>
          }

          <Form.Item
            label="Sumber Dana"
            name="is_all_fund_source"
            initialValue={false}
            className={`${!sumberDanaTypeValue ? 'mb-2' : ''}`}
          >
            <Radio.Group onChange={(e) => this.onChangeSumberDanaType(e.target.value)} value={sumberDanaTypeValue}>
              <Radio value={false}>Pilih Sebagian</Radio>
              <Radio value={true}>Pilih Semua</Radio>
            </Radio.Group>
          </Form.Item>

          {!sumberDanaTypeValue &&
            <Form.Item
              {...tailFormItemLayout}
              name="fund_sources"
              rules={[{ required: true, message: "Wajib diisi" }]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Pilih sumber dana"
                onChange={this.handleChangeSource}
                loading={onLoadFunds}
                disabled={onLoadFunds}
              >
                {(fundList || []).map((item, index) => <Select.Option key={index} value={item.kd_sumber_dana}>{item.name}</Select.Option>)}
              </Select>
            </Form.Item>
          }
        </ConfigProvider>
      </React.Fragment>
    )
  }
}

export default connect(
  ({ auth }) => ({ auth }),
  { fetchProvince, fetchCity, fetchEducation, fetchFunds }
)(DinasKabupaten)
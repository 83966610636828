import axios from './config'
// import history from '../utils/History'
import { message } from 'antd'
import { AUTH_REQUEST, AUTH_RECEIVED, AUTH_FAILED, AUTH_SIGNOUT } from './constans'

export const logIn = (data) => {
  return (dispatch) => {
    dispatch(startAuthentication())

    return new Promise((resolve, reject) => {
      if (typeof data === "object") {
        axios.post(
          'users',
          data,
          { headers: { 'X-API-KEY': process.env.REACT_APP_API_KEY } }
        ).then((response) => {
          const { data, status } = response
          if (status === 200) {
            dispatch(successAuthentication(data.data))
            resolve(data)
          }
        }).catch(error => {
          reject(error)
          dispatch(failAuthentication(error))
        })
      } else {
        axios.post(
          'users',
          {},
          { headers: { 'Authorization': `Bearer ${data}` } }
        ).then((response) => {
          const { data, status } = response
          if (status === 200) {
            dispatch(successAuthentication(data.data))
            resolve(data)
          }
        }).catch(error => {
          reject(error)
          dispatch(failAuthentication(error))
        })
      }
    });
  }
}

export const ssoLogin = (payload) => {
  return () => {
    return new Promise((resolve, reject) => {
      axios.post(
        'sso/login',
        payload,
        { headers: { 'x-api-key': process.env.REACT_APP_API_KEY } },
      ).then((res) => {
        if (res.data.success) {
          resolve(res.data)
        } else {
          message.error('Terdapat kesalahan')
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export const resendOtp = (payload) => {
  return () => {
    return new Promise((resolve, reject) => {
      axios.post(
        'sso/resend-otp',
        payload,
        { headers: { 'x-api-key': process.env.REACT_APP_API_KEY } },
      ).then((res) => {
        if (res.data.success) {
          resolve(res.data)
        } else {
          message.error('Terdapat kesalahan')
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export const otpRequest = (payload) => {
  return () => {
    return new Promise((resolve, reject) => {
      axios.post(
        `sso/verify-otp`,
        payload,
        { headers: { 'x-api-key': process.env.REACT_APP_API_KEY } },
      ).then((res) => {
        let redirectUrl
        if (res.data.data.redirect_url) {
          if (res?.data?.data?.redirect_url.includes("http") || res?.data?.data?.redirect_url.includes("https")) {
            redirectUrl = res.data.data.redirect_url+`?code=${res.data.data.id_token}`
          } else {
            redirectUrl = 'https://'+res.data.data.redirect_url+`?code=${res.data.data.id_token}`
          }
          window.location.replace(redirectUrl)
        } else {
          message.error('Terdapat kesalahan')
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export const doLogout = () => {
  return (dispatch) => {
    dispatch(startAuthentication())
    dispatch(doSignout())
  }
}

const startAuthentication = () => {
  return { type: AUTH_REQUEST }
}

const successAuthentication = (data) => {
  return {
    type: AUTH_RECEIVED,
    data,
  }
}

const failAuthentication = (error) => {
  return { type: AUTH_FAILED, error }
}

const doSignout = () => {
  return { type: AUTH_SIGNOUT }
}

export const agreementUpdate = (payload, userId, token) => {
  return () => {
    return new Promise((resolve, reject) => {
      axios.put(
          `/users/update-agreement/${userId}`,
          payload,
          { headers: { 'x-api-key': token } },
      ).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

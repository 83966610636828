import initialState from './initialState'
import {
  SCHOOL_DISTRICT_REQUEST,
  SCHOOL_DISTRICT_SUCCESS,
  SCHOOL_DISTRICT_FAILED,
} from '../actions/constans'

const schoolDistrict = (state = initialState.schoolDistrict, action = {}) => {
  switch (action.type) {
    case SCHOOL_DISTRICT_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case SCHOOL_DISTRICT_SUCCESS:
      return {
        ...state,
        ...action.data,
        isFetching: false,
      }
    case SCHOOL_DISTRICT_FAILED:
      return {
        ...state,
        isFetching: false,
        data: [],
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

export default schoolDistrict

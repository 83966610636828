// Site Config
export const SET_SITE_CONFIGURATION = 'SET_SITE_CONFIGURATION'

// School Province Transaction
export const SCHOOL_PROVINCE_REQUEST = 'SCHOOL_PROVINCE_REQUEST'
export const SCHOOL_PROVINCE_SUCCESS = 'SCHOOL_PROVINCE_SUCCESS'
export const SCHOOL_PROVINCE_FAILED = 'SCHOOL_PROVINCE_FAILED'
export const TOTAL_TRANSACTION_SUCCESS = 'TOTAL_TRANSACTION_SUCCESS'
export const TOTAL_GOODS_SUCCESS = 'TOTAL_GOODS_SUCCESS'
export const FILTER_REQUEST = 'FILTER_REQUEST'
export const FILTER_SUCCESS = 'FILTER_SUCCESS'
export const FILTER_ERROR = 'FILTER_ERROR'

// Properties
export const PROPERTY_BID_REQUEST = 'PROPERTY_BID_REQUEST'
export const PROPERTY_BID_SUCCESS = 'PROPERTY_BID_SUCCESS'
export const PROPERTY_BID_ERROR = 'PROPERTY_BID_ERROR'

// Auth
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_RECEIVED = 'AUTH_RECEIVED'
export const AUTH_FAILED = 'AUTH_FAILED'
export const AUTH_SIGNOUT = 'AUTH_SIGNOUT'

// USERS
export const USERS_REQUEST = 'USERS_REQUEST'
export const USERS_SUCCESS = 'USERS_SUCCESS'
export const USERS_FAILED = 'USERS_FAILED'

// STATISTIC
export const STATISTIC_REQUEST = 'STATISTIC_REQUEST'
export const STATISTIC_MARKETPLACE_SUCCESS = 'STATISTIC_MARKETPLACE_SUCCESS'
export const STATISTIC_TRANSACTION_FAILED = 'STATISTIC_TRANSACTION_FAILED'

export const EDUCATION_STATISTIC_REQUEST = "EDUCATION_STATISTIC_REQUEST"
export const EDUCATION_STATISTIC_SUCCESS = "EDUCATION_STATISTIC_SUCCESS"
export const EDUCATION_STATISTIC_FAILED = "EDUCATION_STATISTIC_FAILED"

export const FUND_SOURCE_REQUEST = "FUND_SOURCE_REQUEST"
export const FUND_SOURCE_SUCCESS = "FUND_SOURCE_SUCCESS"
export const FUND_SOURCE_FAILED = "FUND_SOURCE_FAILED"

// DISTRICT
export const SCHOOL_DISTRICT_REQUEST = "SCHOOL_DISTRICT_REQUEST"
export const SCHOOL_DISTRICT_SUCCESS = "SCHOOL_DISTRICT_SUCCESS"
export const SCHOOL_DISTRICT_FAILED = "SCHOOL_DISTRICT_FAILED"

// DETAIL SCHOOL
export const DETAIL_SCHOOL_REQUEST = "DETAIL_SCHOOL_REQUEST`"
export const DETAIL_SCHOOL_SUCCESS = "DETAIL_SCHOOL_SUCCESS`"
export const DETAIL_SCHOOL_FAILED = "DETAIL_SCHOOL_FAILED`"
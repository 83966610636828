import axios from './config'

export const activationUser = (token, payload) => {
  return () => {
    return new Promise((resolve, reject) => {
      axios.post(
        `/users/activate/${token}`,
        payload,
        { headers: { 'x-api-key': process.env.REACT_APP_API_KEY } },
      ).then((res) => {
      	resolve(res)
      }).catch((err) => {
        reject(err)
      })
    }) 
  }
}

export const forgotPassword = (payload) => {
  return () => {
    return new Promise((resolve, reject) => {
      axios.post(
        `/users/forgot-password`,
        payload,
        { headers: { 'x-api-key': process.env.REACT_APP_API_KEY } },
      ).then((res) => {
      	resolve(res)
      }).catch((err) => {
        reject(err)
      })
    }) 
  }
}

export const changePassword = (loginToken, payload) => {
  return () => {
    return new Promise((resolve, reject) => {
      axios.put(
        `/users/change-password`,
        payload,
        { headers: { 'x-api-key': loginToken } },
      ).then((res) => {
      	resolve(res)
      }).catch((err) => {
        reject(err)
      })
    }) 
  }
}

export const resetPassword = (token, payload) => {
  return () => {
    return new Promise((resolve, reject) => {
      axios.put(
        `/users/reset-password/${token}`,
        payload,
        { headers: { 'x-api-key': process.env.REACT_APP_API_KEY } },
      ).then((res) => {
      	resolve(res)
      }).catch((err) => {
        reject(err)
      })
    }) 
  }
}

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { compose, createStore, applyMiddleware } from 'redux'
import ReduxPromise from 'redux-promise'
import thunkMiddleware from 'redux-thunk'
import persistState from 'redux-localstorage'
import reducers from './reducers';
import App from './App';
/*import registerServiceWorker from './registerServiceWorker';*/
import NetworkService from './networkService';
import * as serviceWorker from './serviceWorker'

const middlewares = [ReduxPromise, thunkMiddleware]
const rootElement = document.getElementById('root')

if (process.env.NODE_ENV === 'development') {
  
}

const enhancer = compose(
  applyMiddleware(...middlewares),
  persistState('auth', { key: 'AUTH' }),
  persistState('site', { key: 'SITE' }),
  persistState('detailSchool', { key: 'DETAILSCHOOL' }),
)

const store = createStore(reducers, {}, enhancer)

NetworkService.setupInterceptors(store);
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

/*ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  , document.getElementById('root'));
registerServiceWorker();*/

import axios from './config'

export const fetchRole = (token, param) => {
  return () => {
    const urlPage = param ? `roles/${param}` : 'roles'
    return new Promise((resolve, reject) => {
      axios.get(
        urlPage,
        { headers: { 'x-api-key': token } },
      ).then((response) => {
        const { data, status } = response
        if (status === 200) {
          resolve(data.data)
        }
      }).catch(error => {
        reject(error)
      })
    });
  }
}

import React from 'react';
import {
  Form, Input, Button,
  Modal, message,
} from 'antd';
// import { Row, Col } from 'react-bootstrap';    
import { connect } from 'react-redux';
import { rejectRequest } from '../../actions/Users';
// import { SearchOutlined } from '@ant-design/icons';



class RejectModal extends React.Component {
  formRef = React.createRef();
  state = {
    onSubmit: false
  }
  componentDidMount(){
    
  }

  

  render(){
    const { onSubmit } = this.state
    const { auth, isRejectVisible, handleOk, handleCancel } = this.props

    const onFinish = (values) => {
      this.setState({
        onSubmit: true
      })
      this.props.rejectRequest(this.props.requestId, values, auth.id_token)
      .then(() => {
        this.formRef.current.resetFields()
        this.props.rejectStatus(true)
        message.success('Permintaan Izin Berhasil Ditolak')
        this.setState({
          onSubmit: false
        })
      })
      .catch(error => {
        this.setState({
          onSubmit: false
        })
        message.error(error)
      })
    };

    const onFinishFailed = (errorInfo) => {
      
    };

    return(
      <React.Fragment>
        <Modal
          title="Tolak Permintaan Izin"
          visible={isRejectVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            preserve={false}
            ref={this.formRef}
            name="rejectRequest"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="notes"
              rules={[{ required: true, message: 'Silakan beri alasan' }]}
            >
              <Input.TextArea rows={6} placeholder="Masukan pesan" disabled={onSubmit}/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="float-right" loading={onSubmit}>
                Kirim
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

export default connect(
  ({auth}) => ({ auth }),
  { rejectRequest }
)(RejectModal)
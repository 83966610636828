import React from "react"
import Chart from "react-apexcharts"
import { Spin } from 'antd'
import Helper from "../../utils/Helper"

class DonutChart extends React.Component {

  render(){
  	const { data, isLoading } = this.props
console.log('data', data)
    return(                  
			<div>
        {isLoading ?
          <div className="text-center">
            <Spin />
          </div>
        :
          <Chart
            options={{
              chart: {
                width: 380,
                type: 'pie',
              },
              labels: data ? data.label : [],
              tooltip: {
                y: {
                  formatter: function (val) {
                    return Helper.convertSeparator(val)
                  }
                },
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            }}
            series={data ? data.values : []}
            type="pie"
            height={360}
          />
        }
      </div>
    )
  }
}

export default DonutChart

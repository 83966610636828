import React, { useState } from "react";
import { connect } from "react-redux";
import * as Setting from "../utils/CasdoorInit";
import { logIn, agreementUpdate } from "../actions/Auth";
import { AgreementModal } from "../components";
import history from "../utils/History";

const Callback = ({ logIn, agreementUpdate }) => {
  const [userRes, setUserRes] = useState({});
  const [isSubmitAgreement, setIsSubmitAgreement] = useState(false);
  const [toggleAgreementModal, setToggleAgreementModal] = useState(false);

  const authCallback = () => {
    const sdk = Setting.CasdoorSDK;
    var response;

    sdk.signin(Setting.ServerUrl).then((res) => {
      logIn(res.data.accessToken).then((response) => {
        setUserRes(response.data);
        if (!response?.data?.is_agree) {
          setToggleAgreementModal(true);
        } else {
          window.location.reload(false);
        }
      });
    });

    return response;
  };

  const handleSubmitAgreement = (value) => {
    agreementUpdate({ is_agree: value }, userRes.id, userRes?.id_token)
      .then(() => {
        history.push("/");
      })
      .catch(() => {
        setIsSubmitAgreement(false);
      });
  };

  return (
  <>
    {authCallback()}
    <AgreementModal
      isApproveVisible={toggleAgreementModal}
      onSubmit={handleSubmitAgreement}
      isSubmitAgreementLoading={isSubmitAgreement}
    />
  </>
  );
};

export default connect(() => ({}), { logIn, agreementUpdate })(Callback);

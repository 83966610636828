import React from 'react';
import { Accordion, Card } from 'react-bootstrap';

class AccordionCard extends React.Component {
  render(){
    const { title, content, key } = this.props

    return(                  
      <Card className="accordion-card">
        <Accordion.Toggle as={Card.Header} className="accordion-header" eventKey={key}>
          {title}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={key}>
          <Card.Body className="accordion-body">{content}</Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  }
}

export default AccordionCard
import React from 'react';
import {Empty} from 'antd';

const CustomizeNotFound = () => (
    <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
            <span>
                Data Tidak Ditemukan
            </span>
        }
    >
    </Empty>
  );
export default CustomizeNotFound;
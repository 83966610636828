import initialState from './initialState'
import { SET_SITE_CONFIGURATION } from '../actions/constans'

const site = (state = initialState.site, action = {}) => {
  switch (action.type) {
    case SET_SITE_CONFIGURATION:
      return {
        ...state,
        [action.dataKey]: action.dataValue,
      }
    default:
      return state
  }
}

export default site

import axios from './config'
import { message } from 'antd'

export const fetchKey = () => {
  return () => {
    const url = 'keys'
    const payload = { app_name: 'my app' }
    return new Promise((resolve, reject) => {
      axios
        .post(url, payload)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data.token)
            message.success(res.data.token)
          } else {
            message.error('Terdapat kesalahan')
          }
        })
        .catch((err) => {
          reject(err.message)
        })
    })
  }
}

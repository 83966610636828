import React from 'react'
import { connect } from 'react-redux'
/*import { withRouter } from 'react-router-dom'*/
/*import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'*/
import { Container, Card, Row, Col, Button } from 'react-bootstrap'
import { Form, Input, Alert } from 'antd'
import { activationUser, resetPassword } from '../../actions/Password'
import history from '../../utils/History'
import swal from 'sweetalert2'
class Activation extends React.Component {
  state = {
    isSubmit: false,
    token: '',
    isActivation: true
  }

  componentDidMount(){
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.setState({
      token: params.t,
      isActivation:  window.location.pathname.includes('/activation')
    })
  }

  render() {
    const { isSubmit, isActivation } = this.state
    // const { auth } = this.props

    const onFinish = (values) => {
      this.setState({
        isSubmit: true
      })

      if (isActivation) {
        this.props.activationUser(this.state.token, values)
        .then((result) => {
          this.setState({
            isSubmit: false
          })
          swal.fire({
            title: '<h3><strong class="font-maroon">Sukses membuat password!</strong></h3>',
            html:
              '<h4>Password Berhasil dibuat, Silahkan login</h4>',
            showCloseButton: false,
            confirmButtonText:
              '<i class="fa fa-thumbs-up"></i> Login',
            confirmButtonAriaLabel: 'Login',
            icon: 'success',
            allowOutsideClick: () => false
          }).then((result) => {
            if (result) {
              history.push("/login")
            }
          })
        }).catch(error => {
          let msg
          if (error.response) {
            if (error.response.data.error) {
              msg = error?.response?.data?.error
            } else if (error?.response?.data?.message) {
              msg = error?.response?.data?.message
            } else {
              msg = "Terdapat kesalahan"
            }
            console.log(msg)
          }
          this.setState({
            isSubmit: false
          })
        })
      } else {
        this.props.resetPassword(this.state.token, values)
        .then((result) => {
          this.setState({
            isSubmit: false
          })
          swal.fire({
            title: '<h3><strong class="font-maroon">Sukses mengubah password!</strong></h3>',
            html:
              '<h4>Silahkan login dengan menggunakan Password baru Anda</h4>',
            showCloseButton: false,
            confirmButtonText:
              '<i class="fa fa-thumbs-up"></i> Login',
            confirmButtonAriaLabel: 'Login',
            icon: 'success',
            allowOutsideClick: () => false
          }).then((result) => {
            if (result) {
              history.push("/login")
            }
          })
        }).catch((error) => {
          let msg
          if (error.response) {
            if (error.response.data.error) {
              msg = error?.response?.data?.error
            } else if (error?.response?.data?.message) {
              msg = error?.response?.data?.message
            } else {
              msg = "Terdapat kesalahan"
            }
            console.log(msg)
          }
          this.setState({
            isSubmit: false
          })
        })
      }
      
    }

    const onFinishFailed = () => {
      
    }

    return (
      <React.Fragment>
        <div className="h-100">
          <div className="d-flex align-items-center flex-column justify-content-center h-100">
            <Container>
              <Row className="justify-content-center">
                <Col xs="12" md="8" lg="4" className="mb-3">
                  <Card className="card-login login-pg">
                    <Card.Body>
                      <div className="mb-3 text-center">
                        <h3 className="mb-0">{isActivation ? 'Buat' : 'Reset'} Password</h3>
                      </div>
                      <Alert
                        description="Password minimal 8 karakter dan harus mengandung setidaknya 1 huruf kapital dan angka"
                        type="info"
                        className="m-b-20"
                      />
                      <Form
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                      >
                        <Form.Item
                          name="password"
                          placeholder="Password"
                          rules={[
                            { required: true, message: 'Masukkan password Anda' },
                            { pattern: /(?=.*[A-Z])(?=.*\d).{8,}$/, message: 'Password minimal 8 karakter dan harus mengandung setidaknya 1 huruf kapital dan angka\n' },
                            {
                              validator: (rule, value) => {
                                if (value && value.includes(' ')) return Promise.reject('Password mengandung spasi')

                                return Promise.resolve()
                              },
                            },
                          ]}
                        >
                          <Input.Password placeholder="New Password" />
                        </Form.Item>

                        <Form.Item
                          name="confirm_password"
                          placeholder="Confirm Password"
                          rules={[
                            {
                              required: true,
                              message: 'Konfirmasi password Anda',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                  return Promise.resolve()
                                }
                                return Promise.reject(new Error('Password tidak sama'))
                              },
                            }),
                          ]}
                        >
                          <Input.Password placeholder="Confirm Password" />
                        </Form.Item>

                        <Form.Item >
                          <Button className="btn-block mt-4 custom-primary" variant="primary" type="submit">{isSubmit ? 'Loading...' : 'MASUK'}</Button>
                        </Form.Item>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(
  () => ({ }),
  { activationUser, resetPassword }
)(Activation)
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import site from './Site'
import auth from './Auth'
import schoolProvince from './School'
import statisticTransaction from './Statistic'
import educationStatistic from './EducationStatistic'
import fundSource from './FundSource'
import users from './Users'
import schoolDistrict from './District'
import detailSchool from './DetailSchool'

const rootReducer = combineReducers({
  site,
  auth,
  schoolProvince,
  statisticTransaction,
  educationStatistic,
  fundSource,
  users,
  schoolDistrict,
  detailSchool,
  form: formReducer
});

export default rootReducer;

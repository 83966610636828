import React from "react"
import Chart from "react-apexcharts"
import { Spin } from 'antd'
import Helper from "../../utils/Helper"

class ColumnChart extends React.Component {

  render(){
  	const { data, isLoading } = this.props
// n.match(/[\d\.]+|\D+/g)

    return(                  
			<div>
        {isLoading ?
          <div className="text-center">
            <Spin />
          </div>
        :
          <Chart
            options={{
              chart: {
                height: 350,
                type: 'bar',
              },
              plotOptions: {
                bar: {
                  borderRadius: 5,
                  dataLabels: {
                    position: 'top',
                  },
                }
              },
              dataLabels: {
                enabled: true,
                formatter: function (val) {
                  // const value = Helper.convertPrice(val)
                  // const price = value.match(/[\d\.]+|\D+/g)
                  // if (price.length > 1) {
                  //   return parseInt(price[0])+price[1]
                  // } else {
                  //   return parseInt(price[0])
                  // }
                  return Helper.convertPrice(val)
                },
                offsetY: -20,
                style: {
                  fontSize: '12px',
                  colors: ["#304758"]
                }
              },
              xaxis: {
                categories: data ? data.label : [],
                position: 'bottom',
                axisBorder: {
                  show: false
                },
                axisTicks: {
                  show: false
                },
                tooltip: {
                  enabled: true,
                }
              },
              yaxis: {
                axisBorder: {
                  show: false
                },
                axisTicks: {
                  show: false,
                },
                labels: {
                  show: true,
                  formatter: function (val) {
                    return Helper.convertSeparator(val)
                  }
                }
              },
            }}
            series={
              [{
                name: 'Nilai Transaksi Berdasarkan Jenjang',
                data: data ? data.values : []
              }]
            }
            type="bar"
            height={350}
          />
        }
      </div>
    )
  }
}

export default ColumnChart

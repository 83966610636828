import React from 'react';
import {
  Form, Input, Button,
  Modal, message
} from 'antd';
// import { Row, Col } from 'react-bootstrap';    
import { connect } from 'react-redux';
import { approveRequest } from '../../actions/Users';
// import { SearchOutlined } from '@ant-design/icons';



class ApproveModal extends React.Component {
  formRef = React.createRef();
  state = {
    onSubmit: false
  }
  componentDidMount(){
    
  }

  

  render(){
    const { onSubmit } = this.state
    const { isApproveVisible, handleOk, handleCancel, approveRequest, auth } = this.props

    const onFinish = (values) => {
      this.setState({
        onSubmit: true
      })
      approveRequest(this.props.requestId, values, auth.id_token)
      .then(() => {
        this.formRef.current.resetFields()
        this.props.approveStatus(true)
        message.success('Permintaan Izin Berhasil Disetujui')
        this.setState({
          onSubmit: false
        })
      })
      .catch(error => {
        this.setState({
          onSubmit: false
        })
        this.props.approveStatus(false)
        message.error(error)
        this.setState({
          onSubmit: false
        })
      })
    };

    const onFinishFailed = (errorInfo) => {
      
    };

    return(
      <React.Fragment>
        <Modal
          title="Setujui Permintaan Izin"
          visible={isApproveVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            preserve={false}
            ref={this.formRef}
            name="approveRequest"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="notes"
              rules={[{ required: true, message: 'Silakan masukkan alasan' }]}
            >
              <Input.TextArea rows={6} placeholder="Masukan pesan" disabled={onSubmit}/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="float-right" loading={onSubmit}>
                Kirim
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

export default connect(
  ({auth, users}) => ({ auth, users }),
  { approveRequest }
)(ApproveModal)
import axios from 'axios';
import { AUTH_SIGNOUT } from './actions/constans' 
import Swal from 'sweetalert2'; 
import Helper from './utils/Helper';
export default {
  setupInterceptors: (store) => {
    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        if ( response.data.message === 'Unauthorized Token') {
            localStorage.clear();
            store.dispatch({ type: AUTH_SIGNOUT });

            return window.location.href="/";
        }

        if (response?.status === 200 && response?.data?.data?.is_valid === false) {
          Swal.fire(
            `Token Telah Kadaluarsa`,
          ).then(() => {
            localStorage.clear();
            store.dispatch({ type: AUTH_SIGNOUT });
            window.location.href="/"
          })
        }

        return response;
    }, function (error) {
        if (error.response) {
          const { data, status } = error.response
          if (status === 401) {
            Swal.fire(
              `${Helper.capitalizeFirstLetter(data.error) || 'Terjadi kesalahan'}`,
            ).then(() => {
              localStorage.clear();
              store.dispatch({ type: AUTH_SIGNOUT });
              window.location.href="/"
            })
          } else if (status === 429) {
            Swal.fire({
              imageAlt: 'Alert',
              imageWidth: 'auto',
              imageHeight: 200,
              html: `${Helper.capitalizeFirstLetter(data.error) || 'Anda telah melakukan login lebih dari 20 kali dalam kurun waktu 1 jam. Silakan menunggu 1 jam lagi untuk melakukan login kembali.'}`,
              showCloseButton: true,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> Oke',
              confirmButtonAriaLabel: 'Oke',
              confirmButtonColor: '#ff5f5f',
              customClass: {
                  confirmButton: 'btn btn-detail-primary btn-border-red rounded w-50'
              }
            })
          } else if (data.error) {
            Swal.fire({
              imageAlt: 'Alert',
              imageWidth: 'auto',
              imageHeight: 200,
              html: `<h5 class="m-t-10">${Helper.capitalizeFirstLetter(data.error)}</h5>`,
              showCloseButton: true,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> Oke',
              confirmButtonAriaLabel: 'Oke',
              confirmButtonColor: '#ff5f5f',
              customClass: {
                  confirmButton: 'btn btn-detail-primary btn-border-red rounded w-50'
              }
            })
          } else if (data?.message) {
            Swal.fire({
              imageAlt: 'Alert',
              imageWidth: 'auto',
              imageHeight: 200,
              html: `<h5 class="m-t-10">${Helper.capitalizeFirstLetter(data?.message)}</h5>`,
              showCloseButton: true,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> Oke',
              confirmButtonAriaLabel: 'Oke',
              confirmButtonColor: '#ff5f5f',
              customClass: {
                  confirmButton: 'btn btn-detail-primary btn-border-red rounded w-50'
              }
            })
          } else if (error?.message) {
            Swal.fire({
              imageAlt: 'Alert',
              imageWidth: 'auto',
              imageHeight: 200,
              html: `<h5 class="m-t-10">${Helper.capitalizeFirstLetter(error?.message)}</h5>`,
              showCloseButton: true,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> Oke',
              confirmButtonAriaLabel: 'Oke',
              confirmButtonColor: '#ff5f5f',
              customClass: {
                  confirmButton: 'btn btn-detail-primary btn-border-red rounded w-50'
              }
            })
          } else {
            Swal.fire({
              imageAlt: 'Alert',
              imageWidth: 'auto',
              imageHeight: 200,
              html: `<h5 class="m-t-10">Terdapat kesalahan</h5>`,
              showCloseButton: true,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> Oke',
              confirmButtonAriaLabel: 'Oke',
              confirmButtonColor: '#ff5f5f',
              customClass: {
                  confirmButton: 'btn btn-detail-primary btn-border-red rounded w-50'
              }
            })
          }
        }
        // if (error.response.status === 401) {
        //     localStorage.clear();
        //     store.dispatch({ type: AUTH_SIGNOUT });
        //     window.location.href="/";
        //     return swal.fire({
        //         title: '<h3><strong class="red-font">Oops!</strong></h3>',
        //         imageAlt: 'Alert',
        //         imageWidth: 'auto',
        //         imageHeight: 200,
        //         html:
        //           `<h5>Token is expired</h5>`,
        //         showCloseButton: true,
        //         confirmButtonText:
        //           '<i class="fa fa-thumbs-up"></i> Okay',
        //         confirmButtonAriaLabel: 'Okay',
        //         confirmButtonColor: '#ff5f5f',
        //         customClass: {
        //             confirmButton: 'btn btn-detail-primary btn-border-red rounded w-50'
        //         }
        //       })
        // }
        //catches if the session ended!
        return Promise.reject(error);
    });

  }
};
import initialState from './initialState'
import {
  SCHOOL_PROVINCE_REQUEST,
  SCHOOL_PROVINCE_SUCCESS,
  SCHOOL_PROVINCE_FAILED,
} from '../actions/constans'

const schoolProvince = (state = initialState.schoolProvince, action = {}) => {
  switch (action.type) {
    case SCHOOL_PROVINCE_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case SCHOOL_PROVINCE_SUCCESS:
      return {
        ...state,
        ...action.data,
        isFetching: false,
      }
    case SCHOOL_PROVINCE_FAILED:
      return {
        ...state,
        isFetching: false,
        data: [],
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

export default schoolProvince

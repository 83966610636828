import initialState from './initialState'
import {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAILED,
} from '../actions/constans'

const users = (state = initialState.users, action = {}) => {
  switch (action.type) {
    case USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case USERS_SUCCESS:
      return {
        ...state,
        ...action.data,
        isFetching: false,
      }
    case USERS_FAILED:
      return {
        ...state,
        isFetching: false,
        data: [],
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

export default users

import React from 'react';
import { Form, Select, Radio, ConfigProvider } from 'antd'; 
import { connect } from 'react-redux';
import { fetchProvince, fetchCity, fetchEducation, fetchFunds } from '../../actions/AdditionalForm';
import { isEmpty, map as lodashMap } from 'lodash';
import CustomizeNotFound from '../CustomizeNotFound';
// import { ConfigConsumer } from 'antd/lib/config-provider';

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class Pegawai extends React.Component {
  state = {
    provinceList: [],
    cityList: [],
    onLoadProvince: true,
    onLoadCity: false,
    isCityReady: true,
    onLoadEducation: true,
    selectedProvince: '',
    educationList: [],
    fundList: [],
    onLoadFunds: true,
    jenjangTypeValue: false,
    sumberDanaTypeValue: false,
    cityTypeValue: false,
    provinceTypeValue: false,
    isFinishFetch: true
  }


  componentDidMount(){
    // const { isEdit, editValue } = this.props
    this.props.fetchProvince(this.props.auth.id_token || process.env.REACT_APP_API_KEY, 'limit=34')
    .then((result) => {
      const ascendingResult = result.items.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      this.setState({
        onLoadProvince: false,
        provinceList: ascendingResult
      })
    }).catch((error) => {
      this.setState({
        onLoadProvince: false,
        provinceList: []
      })
    })

    this.props.fetchEducation(this.props.auth.id_token || process.env.REACT_APP_API_KEY)
    .then((result) => {
      this.setState({
        onLoadEducation: false,
        educationList: result.items
      })
    }).catch((error) => {
      this.setState({
        onLoadEducation: false,
        educationList: []
      })
    })

    this.props.fetchFunds(this.props.auth.id_token || process.env.REACT_APP_API_KEY)
    .then((result) => {
      this.setState({
        onLoadFunds: false,
        fundList: result.items
      })
    }).catch((error) => {
      this.setState({
        onLoadFunds: false,
        fundList: []
      })
    })
  }

  componentDidUpdate(){
    const { isEdit } = this.props
    const { isFinishFetch } = this.state

    if (isFinishFetch) {
      if (!this.state.onLoadProvince && !this.state.onLoadEducation && !this.state.onLoadFunds) {
        this.setState({
          isFinishFetch: false
        })
        if (isEdit) {
          this.handleEditForm() 
        }
      }
    }
  }

  handleEditForm = () => {
    const { isEdit, editValue } = this.props

    setTimeout(() => {
      if (editValue?.education === true) {
        this.onChangeJenjangType(true)
      } else {
        this.handleChangeEducation(editValue?.education)
      }

      if (editValue?.fund === true) {
        this.onChangeSumberDanaType(true)
      } else {
        this.handleChangeSource(editValue?.fund)
      }

      if (editValue?.province === 'All' || editValue?.province === true) {
        this.onChangeProvinceType(true)
      } else {
        this.handleChangeProvince(editValue?.province, isEdit)
      }

      if (editValue?.city === 'All' || editValue?.city === true) {
        this.onChangeCityType(true)
      }
    }, 500)
  }

  getCities = (limit, params) => {
    this.props.fetchCity(this.props.auth.id_token || process.env.REACT_APP_API_KEY, `limit=${limit}&${params}`)
    .then((result) => {
      const ascendingResult = result.items.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })   
      this.setState({
        onLoadCity: false,
        isCityReady: false,
        cityList: ascendingResult
      })
    }).catch((error) => {
      this.setState({
        onLoadCity: false,
        isCityReady: false,
        cityList: []
      })
    }) 
  }

  handleChangeProvince = (value, isEdit) => {
    const { editValue } = this.props
    let selectedCity = isEdit !== true && this.props.formIdentity.current.getFieldValue('city')
    let params
    let newProvinceVal
    let arrProvince = []
    let provinceArr = []

    if (isEdit === true) { //find province id
      value.forEach(name => {
        newProvinceVal = (this.state.provinceList || []).find(item => item.name === name)
        const provId = newProvinceVal?.id
        provinceArr.push(provId)
      })
      this.props.formIdentity.current.setFieldsValue({province: provinceArr})
    } else {
      provinceArr = value
    }

    if (provinceArr.length > 1) {
        params = `province_ids=${provinceArr}`
        provinceArr.forEach(id => {
          newProvinceVal = (this.state.provinceList || []).find(item => item.id === id)
          arrProvince.push({id: newProvinceVal.id, kd_prop: newProvinceVal.kd_prop})
        })
    } else if (provinceArr.length === 1) {
      params = `province_ids=${provinceArr}`
      newProvinceVal = (this.state.provinceList || []).find(item => item.id === provinceArr[0])
      arrProvince.push({id: newProvinceVal.id, kd_prop: newProvinceVal.kd_prop})
    } else {
      newProvinceVal = ''
      arrProvince = []
    }

    const limit = '514'
    this.setState({
      selectedProvince: value,
      onLoadCity: true,
      isCityReady: true,
    })
    this.props.sendProvince(arrProvince) //send value to parent

    if (params) {
      if (!this.state.provinceTypeValue) {
        this.getCities(limit, params)
        if (isEdit === true && (editValue?.city !== true && editValue?.city !== "All")) {
          setTimeout(() => {
            this.handleChangeCity(editValue?.city, false, isEdit)
          }, 300) 
        }
      }
    } else {
      this.setState({
        onLoadCity: false,
        isCityReady: true,
        cityList: []
      })
    }

    if (selectedCity) {
      setTimeout(() => {
        this.handleChangeCity(selectedCity, true)
      }, 500);
    }

  }

  handleChangeCity = (value, isProvinceChange, isEdit) => {
    let isCityExist = isEdit !== true && this.props.formIdentity.current.getFieldValue('city')

    let newCityVal
    let arrCity = []
    let reinitialValue = []
    let cityUpdateArr = []

    if (isEdit === true) { //find province id
      value.forEach(name => {
        newCityVal = (this.state.cityList || []).find(item => item.name === name) 
        const cityId = newCityVal?.id
        cityUpdateArr.push(cityId)
      })
      this.props.formIdentity.current.setFieldsValue({city: cityUpdateArr})
    } else {
      cityUpdateArr = value
    }

    if (cityUpdateArr.length > 1) {
      cityUpdateArr.forEach(id => {
        newCityVal = (this.state.cityList || []).find(item => item.id === id)
        if (newCityVal) {
          arrCity.push({id: newCityVal.id, kd_kabkota: newCityVal.kd_kab_kota})
          reinitialValue.push(newCityVal.id)
        }
      })
    } else if (cityUpdateArr.length === 1) {
      newCityVal = (this.state.cityList || []).find(item => item.id === cityUpdateArr[0])
      if (newCityVal) {
        arrCity.push({id: newCityVal.id, kd_kabkota: newCityVal.kd_kab_kota})
        reinitialValue.push(newCityVal.id)
      }
    } else {
      newCityVal = ''
      arrCity = []
      reinitialValue = []
    }

    if (isEmpty(cityUpdateArr)) {
      newCityVal = ''
      arrCity = [] 
      reinitialValue = []
    }

    if (isProvinceChange) {
      this.props.formIdentity.current.setFieldsValue({
        city: reinitialValue,
      })
    }

    if (isCityExist?.length === 0) {
      this.props.formIdentity.current.setFieldsValue({
        city: undefined,
        is_all_cities: false,
      })
    }

    this.props.sendCity(arrCity) //send value to parent
  }

  handleChangeEducation = (value) => {
    this.props.sendEducation(value)
  }

  handleChangeSource = (value) => {
    let newFundsVal
    let arrFund = []
    if (value.length > 1) {
      value.forEach(id => {
        newFundsVal = (this.state.fundList || []).find(item => item.kd_sumber_dana === id) || ''
        if(newFundsVal){
          arrFund.push({kd_sumber_dana: newFundsVal.kd_sumber_dana, fund_source_name: newFundsVal.name})
        }
      })
    } else if (value.length === 1) {
      newFundsVal = (this.state.fundList || []).find(item => item.kd_sumber_dana === value[0]) || value[0]
      arrFund.push({kd_sumber_dana: newFundsVal.kd_sumber_dana, fund_source_name: newFundsVal.name})
    } else {
      newFundsVal = ''
      arrFund = []
    }

    if (this.props.isEdit) {
      const sumberDana = lodashMap(arrFund, "kd_sumber_dana")
      this.props.formIdentity.current.setFieldsValue({
        fund_sources: sumberDana,
      })      
    }

    this.props.sendSource(arrFund) //send value to parent
  }

  onChangeJenjangType = (e) => {
    this.setState({ jenjangTypeValue: e })

    if (e) {
      this.props.formIdentity.current.setFieldsValue({
        education_stage: undefined,
        is_all_education_stage: true
      })
      this.props.sendEducation([]) //send empty array if jenjang all
    }
  }

  onChangeSumberDanaType = (e) => {
    this.setState({ sumberDanaTypeValue: e })

    if (e) {
      this.props.formIdentity.current.setFieldsValue({
        fund_sources: undefined,
        is_all_fund_source: true
      })
      this.props.sendSource([]) //send empty array if source all
    }
  }

  onChangeCityType = (e) => {
    this.setState({ cityTypeValue: e })

    if (e) {
      this.props.formIdentity.current.setFieldsValue({
        city: undefined,
        is_all_cities: true
      })
      this.props.sendCity([]) //send empty array if city all
    }
  }

  onChangeProvinceType = (e) => {
    this.setState({ provinceTypeValue: e })
    if (e) {
      this.props.formIdentity.current.setFieldsValue({
        province: undefined,
        is_all_provinces: true
      })
      this.props.sendProvince([]) //send empty array if city all
      this.getCities('514', 'is_all=1')
    } else {
      //clear city when province back to selected
      this.props.formIdentity.current.setFieldsValue({
        city: undefined,
        is_all_cities: false
      })
      this.setState({
        onLoadCity: false,
        isCityReady: true,
        cityList: [],
        cityTypeValue: false
      })
      this.props.sendCity([])
    }
  }

  render(){
    const {
      provinceList, onLoadProvince, cityList, onLoadCity,
      educationList, onLoadEducation, isCityReady, fundList,
      onLoadFunds, jenjangTypeValue, sumberDanaTypeValue,
      cityTypeValue, provinceTypeValue,
    } = this.state

    return(
      <React.Fragment>
        <ConfigProvider renderEmpty={CustomizeNotFound}>
          <Form.Item
            label="Wilayah"
            name="is_all_provinces"
            initialValue={false}
            className={`${!provinceTypeValue ? 'mb-2' : ''}`}
          >
            <Radio.Group onChange={(e) => this.onChangeProvinceType(e.target.value)} value={provinceTypeValue}>
              <Radio value={false}>Pilih Sebagian</Radio>
              <Radio value={true}>Pilih Semua</Radio>
            </Radio.Group>
          </Form.Item>

          {!provinceTypeValue &&
            <Form.Item
              {...tailFormItemLayout}
              name="province"
              rules={[{ required: true, message: "Wajib diisi" }]}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                style={{ width: '100%' }}
                placeholder="Pilih provinsi"
                onChange={this.handleChangeProvince}
                loading={onLoadProvince}
                disabled={onLoadProvince}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {(provinceList || []).map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
              </Select>
            </Form.Item>
          }

          <Form.Item
            label="Kota"
            name="is_all_cities"
            initialValue={false}
            className={`${!cityTypeValue ? 'mb-2' : ''}`}
          >
            <Radio.Group onChange={(e) => this.onChangeCityType(e.target.value)} value={cityTypeValue}>
              <Radio disabled={isCityReady} value={false}>Pilih Sebagian</Radio>
              <Radio disabled={isCityReady} value={true}>Pilih Semua</Radio>
            </Radio.Group>
          </Form.Item>

          {!cityTypeValue &&
            <Form.Item
              {...tailFormItemLayout}
              name="city"
              rules={[{ required: true, message: "Wajib diisi" }]}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                style={{ width: '100%' }}
                placeholder="Pilih kabupaten/kota"
                onChange={(val) => this.handleChangeCity(val)}
                disabled={isCityReady}
                loading={onLoadCity}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {(cityList || []).map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
              </Select>
            </Form.Item>
          }

          <Form.Item
            label="Jenjang Pendidikan"
            name="is_all_education_stage"
            initialValue={false}
            className={`${!jenjangTypeValue ? 'mb-2' : ''}`}
          >
            <Radio.Group onChange={(e) => this.onChangeJenjangType(e.target.value)} value={jenjangTypeValue}>
              <Radio value={false}>Pilih Sebagian</Radio>
              <Radio value={true}>Pilih Semua</Radio>
            </Radio.Group>
          </Form.Item>

          {!jenjangTypeValue &&
            <Form.Item
              {...tailFormItemLayout}
              name="education_stage"
              rules={[{ required: true, message: "Wajib diisi" }]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Pilih jenjang pendidikan"
                onChange={this.handleChangeEducation}
                loading={onLoadEducation}
                disabled={onLoadEducation}
              >
              {(educationList || []).map((item, index) => <Select.Option key={index} value={item.stage}>{item.stage}</Select.Option>)}
              </Select>
            </Form.Item>
          }

          <Form.Item
            label="Sumber Dana"
            name="is_all_fund_source"
            initialValue={false}
            className={`${!sumberDanaTypeValue ? 'mb-2' : ''}`}
          >
            <Radio.Group onChange={(e) => this.onChangeSumberDanaType(e.target.value)} value={sumberDanaTypeValue}>
              <Radio value={false}>Pilih Sebagian</Radio>
              <Radio value={true}>Pilih Semua</Radio>
            </Radio.Group>
          </Form.Item>

          {!sumberDanaTypeValue &&
            <Form.Item
              {...tailFormItemLayout}
              name="fund_sources"
              rules={[{ required: true, message: "Wajib diisi" }]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Pilih sumber dana"
                onChange={this.handleChangeSource}
                loading={onLoadFunds}
                disabled={onLoadFunds}
              >
                {(fundList || []).map((item, index) => <Select.Option key={index} value={item.kd_sumber_dana}>{item.name}</Select.Option>)}
              </Select>
            </Form.Item>
          }
        </ConfigProvider>
      </React.Fragment>
    )
  }
}

export default connect(
  ({auth}) => ({ auth }),
  { fetchProvince, fetchCity, fetchEducation, fetchFunds }
)(Pegawai)
import initialState from './initialState'
import {
  DETAIL_SCHOOL_REQUEST,
  DETAIL_SCHOOL_SUCCESS,
  DETAIL_SCHOOL_FAILED,
} from '../actions/constans'

const detailSchool = (state = initialState.detailSchool, action = {}) => {
  switch (action.type) {
    case DETAIL_SCHOOL_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case DETAIL_SCHOOL_SUCCESS:
      return {
        ...state,
        ...action.data,
        isFetching: false,
      }
    case DETAIL_SCHOOL_FAILED:
      return {
        ...state,
        isFetching: false,
        data: [],
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

export default detailSchool

import React from "react"
import Chart from "react-apexcharts"
import { Spin } from 'antd'
import Helper from "../../utils/Helper"

class BarChart extends React.Component {

  render(){
  	const { data, height, isLoading } = this.props

    return(                  
			<div>
        {isLoading ?
          <div className="text-center">
            <Spin />
          </div>
        :
          <Chart
            options={{
              chart: {
                type: 'bar',
                height: 350
              },
              plotOptions: {
                bar: {
                  borderRadius: 4,
                  horizontal: true,
                }
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return Helper.convertSeparator(val)
                  }
                },
              },
              dataLabels: {
                enabled: false
              },
              xaxis: {
                categories: data ? data.label : [],
                labels: {
                  show: true,
                  formatter: function (val) {
                    return Helper.convertSeparator(val)
                  }
                }
              }
            }}
            series={
              [{
               data: data ? data.values : []
              }]
            }
            type="bar"
            height={height || 350}
          />
        }
      </div>
    )
  }
}

export default BarChart

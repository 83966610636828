import React from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap';

const Wraper = styled.div`
  display:flex;
  flex:1;
  height: 50vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px 0;
`

const LoaderComponent = () => {
  return(
    <Wraper>
      <Spinner animation="border" variant="warning" />
      <span className="sr-only">Loading...</span>
    </Wraper>
  )
}

export default LoaderComponent

import React from "react";
import { connect } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import page404 from "./pages/page404";
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Layout from "./components/Layout";
import history from "./utils/History";
// import { useLocation } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "antd/dist/antd.css";
import Login from "./pages";
import LoginSso from "./pages/loginSso";
import Activation from "./pages/activation";
import Registration from "./pages/registration";
import Callback from "./pages/Callback";

class App extends React.Component {
  componentDidMount() {
    document.title = "Siplah - Admin";
    const { auth } = this.props;
    if (auth.isAuthenticated) {
      if (history.location.pathname.includes("/login")) {
        history.push("/");
      } /*else {
        history.push(history.location.pathname)
        console.log('history', history.location.pathname)
      }*/
    } /* else {
      history.push(history.location.pathname)
      console.log('history', history.location.pathname)
    }*/
  }

  componentDidUpdate(prevState) {
    const { auth } = this.props;
    // const path = window.location.pathname
    if (auth !== prevState.auth) {
      const { isAuthenticated } = auth;
      if (isAuthenticated) {
        if (auth.is_agree) {
          history.push("/dashboard");
        }
      }
    }
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/sso" component={LoginSso} />
          <Route exact path="/activation" component={Activation} />
          <Route exact path="/reset-password" component={Activation} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/registration" component={Registration} />
          <Route exact path="/callback" component={Callback} />
          <Route path="/" component={Layout} />

          <Route component={page404} />
        </Switch>
      </Router>
    );
  }
}

export default connect(({ auth }) => ({ auth }), { Login })(App);
// export default App;

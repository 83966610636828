import React from 'react'
import { Table, Card, Button } from 'antd'
import Helper from '../../utils/Helper'
import isEqual from 'lodash/isEqual'

class TransactionByCategory extends React.Component{
  state = {
    txByCategory: {
      data: [],
      isFetching: true
    },
    columns: [
      {
        title: 'No',
        dataIndex: '',
        key: 'No',
        width: 50,
        render: (text, record, index) => index + 1,
      },
      {
        title: 'Kategori',
        dataIndex: 'category_name',
        key: 'category_name',
        render: text => text || '-',
      },
      {
        title: 'Nilai Transaksi',
        dataIndex: 'tx_total',
        key: 'tx_total',
        align: 'right',
        render: text => 'Rp '+Helper.formatMoney(text) || '-',
        sorter: (a, b) => a.tx_total - b.tx_total,
      },
    ],
  }

  componentDidUpdate(prevProps, prevState){
    const { dataTable } = this.props
    const { txByCategory } = this.state

    if (!isEqual(dataTable, txByCategory)) {
      this.setState({
        txByCategory: {
          data: dataTable?.data,
          isFetching: dataTable?.isFetching
        }
      }) 
    }
  }

  render(){
    const { columns, txByCategory } = this.state
    const { isByCategoryDownloading } = this.props
    return(
      <Card className="h-100">
        <h5 className="text-left pb-2">Total Nilai Transaksi per Kategori</h5>
        <Table
          bordered
          rowKey="category_id"
          columns={columns}
          dataSource={txByCategory?.data}
          loading={txByCategory?.isFetching}
          pagination={false}
        />
        <div className="float-right mt-3">
          <Button
            disabled={txByCategory?.isFetching}
            loading={isByCategoryDownloading}
            type="primary" onClick={() => this.props.handleUnduh()}>Unduh</Button>
        </div>
      </Card>
    )
  }
}

export default TransactionByCategory

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { last, first, map as lodashMap } from 'lodash'
import { Layout, Menu, Tooltip } from 'antd'
import {
  HomeOutlined,
  TeamOutlined,
  // TransactionOutlined,
  // BankOutlined,
  ShopOutlined,
  // DropboxOutlined,
  LoginOutlined,
  // DatabaseOutlined,
  DollarOutlined,
  FieldTimeOutlined,
  DeploymentUnitOutlined,
  BookOutlined,
} from '@ant-design/icons';
import styled from 'styled-components'
import history from '../../utils/History'
import { updateSiteConfiguration } from '../../actions/site'
import routes from '../../routes'

const { Sider } = Layout
const { SubMenu } = Menu

const SideNav = styled(Sider)`
  .ant-menu-root.ant-menu-inline{
    margin-top: 4px;
  }
  ::-webkit-scrollbar {
    width: 4px;
  };
  ::-webkit-scrollbar-track {
    background: lightgrey;
  };
  ::-webkit-scrollbar-thumb {
    background: darkgray;
  };
  ::-webkit-scrollbar-thumb:hover {
    background: darkgrey; 
  };
`
/*const WrapperAvatar = styled(Row)`
  margin: 10px 0 0 10px;
  .avatar {
    text-align: ${(props) => (props.iscollapsed ? 'center' : 'left')};
  };
  .name {
    display: ${(props) => (props.iscollapsed ? 'none' : 'block')};
    h4 {
      color: white;
      margin-bottom: 0;
    }
  };
`*/

class Sidebar extends React.Component {
  state = {
    role: null,
    newRoutes: []
  }
  componentDidMount(){
    if (this.props?.auth?.roles) {
      this.setState({
        role: first(this.props.auth.roles)
      })
    }

    let reRoutes = []
    routes.forEach(item => {
      const newValue = item?.allowedRoles?.includes(first(this.props.auth.roles)?.role_name) && item
      newValue && reRoutes.push(newValue)
    })

    this.setState({
      newRoutes: lodashMap(reRoutes, 'path')
    })
  }

  handleKeys(param, isOpenSub) {
    const { updateSiteConfiguration } = this.props

    if (isOpenSub) {
      updateSiteConfiguration('activeSubPage', last(param))
    } else {
      updateSiteConfiguration('activePage', first(param.keyPath))
      history.push(`/${first(param.keyPath)}`)
      if (param.keyPath.length > 1) {
        updateSiteConfiguration('activeSubPage', last(param.keyPath))
      } else {
        updateSiteConfiguration('activeSubPage', first(param.keyPath))
      }
    }
  }

  handleCheckAccess = (path) => {
    return this.state.newRoutes.some((item) => path.includes(item))
  }

  render() {
    const { newRoutes } = this.state
    const { site } = this.props
    return (
      <SideNav
        theme="light"
        breakpoint="lg"
        collapsedWidth="0"
        width={250}
        onBreakpoint={broken => {
          // console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          // console.log(collapsed, type);
        }}
      >
        <div className="logo">
          <img src="/Assets/img/kemdikbud_logo.png" className="img-fluid px-3" alt=""/>
        </div>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[site.activePage]}
          openKeys={[site.activeSubPage]}
          onClick={(e) => this.handleKeys(e)}
          onOpenChange={(e) => this.handleKeys(e, true)}
        >
          {newRoutes?.includes('/dashboard') &&
            <Menu.Item key="dashboard">
              <div className="d-flex align-items-center">
                <HomeOutlined/>
                <span className="nav-text">Ringkasan</span>
              </div>
            </Menu.Item>
          }
          {newRoutes?.includes('/sla-compliance') &&
            <Menu.Item key="sla-compliance">
              <div className="d-flex align-items-center">
                <FieldTimeOutlined />
                <span className="nav-text">Tingkat Kepatuhan SLA</span>
              </div>
            </Menu.Item>
          }
          {newRoutes?.includes('/mapping-breaches') &&
            <Menu.Item key="mapping-breaches">
              <div className="d-flex align-items-center">
                <BookOutlined />
                <span className="nav-text">Ringkasan Breach Pemetaan</span>
              </div>
            </Menu.Item>
          }
          {this.handleCheckAccess(['/transaction-all', '/transaction-list', '/source-transaction']) &&
            <SubMenu
              key="transaction"
              title={(
                <span>
                  <DollarOutlined />
                  <span>Transaksi</span>
                </span>
              )}
            >
              {newRoutes?.includes('/transaction-all') &&
                <Menu.Item key="transaction-all">
                  <span className="nav-text">Daftar Transaksi</span>
                </Menu.Item>
              }
              {/* {newRoutes?.includes('/transaction-list') &&
                <Menu.Item key="transaction-list">
                  <span className="nav-text">Transaksi Berdasarkan Sekolah</span>
                </Menu.Item>
              } */}
              {newRoutes?.includes('/source-transaction') &&
                <Menu.Item key="source-transaction">
                  <span className="nav-text">Transaksi Sumber Dana</span>
                </Menu.Item>
              }
            </SubMenu>
          }

          {/* <Menu.Item key="transaction-all">
            <div className="d-flex align-items-center">
              <DatabaseOutlined />
              <span className="nav-text">Transaksi Keseluruhan</span>
            </div>
          </Menu.Item>
          <Menu.Item key="transaction-list">
            <div className="d-flex align-items-center">
              <BankOutlined />
              <span className="nav-text">Transaksi Berdasarkan Sekolah</span>
            </div>
          </Menu.Item>
          <Menu.Item key="source-transaction">
            <div className="d-flex align-items-center">
              <TransactionOutlined />
              <span className="nav-text">Transaksi Sumber Dana</span>
            </div>
          </Menu.Item> */}

          {this.handleCheckAccess(['/pdn-umkm-list', '/goods-list', '/store-list']) &&
            <SubMenu
              key="stores-goods"
              title={(
                <span>
                  <ShopOutlined />
                  <span>Barang & Penyedia</span>
                </span>
              )}
            >
              {newRoutes?.includes('/pdn-umkm-list') &&
                <Menu.Item key="pdn-umkm-list">
                  <span className="nav-text">PDN dan UMKM</span>
                </Menu.Item>
              }
              {newRoutes?.includes('/goods-list') &&
                <Menu.Item key="goods-list">
                  <span className="nav-text">Daftar Barang</span>
                </Menu.Item>
              }
              {newRoutes?.includes('/store-list') &&
                <Menu.Item key="store-list">
                  <span className="nav-text">Daftar Penyedia</span>
                </Menu.Item>
              }
              {newRoutes?.includes('/goods-transaction') &&
                <Menu.Item key="goods-transaction">
                  <span className="nav-text">Daftar Transaksi Barang</span>
                </Menu.Item>
              }
              {newRoutes?.includes('/merchants-transaction') &&
                <Menu.Item key="merchants-transaction">
                  <span className="nav-text">Daftar Transaksi Mitra</span>
                </Menu.Item>
              }
            </SubMenu>
          }
          {this.handleCheckAccess(['/data-gap']) &&
            <SubMenu
              key="data-agregate"
              title={(
                <span>
                  <DeploymentUnitOutlined />
                  <span>Data Agregasi</span>
                </span>
              )}
            >
              <Menu.Item key="data-gap">
                <span className="nav-text">Selisih Data</span>
              </Menu.Item>
              <Menu.Item key="general-data-quality">
                <span className="nav-text">Kualitas Data Umum</span>
              </Menu.Item>
              <Menu.Item key="detail-data-quality">
                <span className="nav-text">Kualitas Data Detail</span>
              </Menu.Item>
              <Menu.Item key="dqm-integration-transaction">
                <Tooltip placement="top" title="Data Yang Perlu Diperbaiki Sesuai dengan Standar Kualitas Data">
                  <span className="nav-text">Data Yang Perlu Diperbaiki Sesuai dengan Standar Kualitas Data</span>
                </Tooltip>
              </Menu.Item>
              
              
            </SubMenu>
          }
          {/* <Menu.Item key="store-list">
            <div className="d-flex align-items-center">
              <ShopOutlined />
              <span className="nav-text">Daftar Toko</span>
            </div>
          </Menu.Item>
          <Menu.Item key="goods-list">
            <div className="d-flex align-items-center">
              <DropboxOutlined />
              <span className="nav-text">Daftar Barang</span>
            </div>
          </Menu.Item> */}

          {newRoutes?.includes('/sso-client') &&
            <Menu.Item key="sso-client">
              <div className="d-flex align-items-center">
                <LoginOutlined />
                <span className="nav-text">Klien SSO</span>
              </div>
            </Menu.Item>
          }

          {this.handleCheckAccess(['/user-list', '/approval-permission']) &&
            <SubMenu
              key="user-management"
              title={(
                <span>
                  <TeamOutlined />
                  <span>Manajemen Pengguna</span>
                </span>
              )}
            >
              {newRoutes?.includes('/user-list') &&
                <Menu.Item key="user-list">
                  <span className="nav-text">Daftar Pengguna</span>
                </Menu.Item>
              }
              {newRoutes?.includes('/approval-permission') &&
                <Menu.Item key="approval-permission">
                  <span className="nav-text">Persetujuan Pengguna</span>
                </Menu.Item>
              }
              {newRoutes?.includes('/approval-permission/create') &&
                <Menu.Item key="approval-permission/create">
                  <span className="nav-text">Tambah Pengguna</span>
                </Menu.Item>
              }
              {newRoutes?.includes('/approval-permission/log') &&
                <Menu.Item key="approval-permission/log">
                  <span className="nav-text">Aktivitas Pengguna</span>
                </Menu.Item>
              }
            </SubMenu>
          }
        </Menu>
      </SideNav>
    )
  }
}


export default withRouter(connect(
  ({ site, auth }) => ({ site, auth }),
  { updateSiteConfiguration },
)(Sidebar))

import axios from './config'
import { message } from 'antd'

export const addRegistration = (token, payload) => {
  return () => {
    const url = 'permissions/requests'

    return new Promise((resolve, reject) => {
      axios
        .post(url, payload, { headers: { 'x-api-key': token } })
        .then((res) => {
          if (res.data.success) {
            resolve('Permintaan hak akses berhasil dibuat. Notifikasi persetujuan hak akses akan dikirim via email. Silakan cek email secara berkala untuk menyelesaikan registrasi.')
            message.success('Permintaan hak akses berhasil dibuat. Notifikasi persetujuan hak akses akan dikirim via email. Silakan cek email secara berkala untuk menyelesaikan registrasi.', 10)
          } else if (res.status === 429) {
            message.error('Anda telah melakukan registrasi lebih dari 20 kali dalam kurun waktu 1 jam. Silakan menunggu 1 jam lagi untuk melakukan registrasi kembali.', 10)
          } else {
            message.error('Terdapat kesalahan')
          }
        })
        .catch((err) => {
          reject(err.message)
        })
    })
  }
}

import initialState from './initialState'
import {
  EDUCATION_STATISTIC_REQUEST,
  EDUCATION_STATISTIC_SUCCESS,
  EDUCATION_STATISTIC_FAILED,
} from '../actions/constans'

const educationStatistic = (state = initialState.educationStatistic, action = {}) => {
  switch (action.type) {
    case EDUCATION_STATISTIC_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case EDUCATION_STATISTIC_SUCCESS:
      return {
        ...state,
        ...action.data,
        isFetching: false,
      }
    case EDUCATION_STATISTIC_FAILED:
      return {
        ...state,
        isFetching: false,
        data: [],
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

export default educationStatistic

import initialState from './initialState'
import {
  FUND_SOURCE_REQUEST,
  FUND_SOURCE_SUCCESS,
  FUND_SOURCE_FAILED,
} from '../actions/constans'

const fundSource = (state = initialState.fundSource, action = {}) => {
  switch (action.type) {
    case FUND_SOURCE_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case FUND_SOURCE_SUCCESS:
      return {
        ...state,
        ...action.data,
        isFetching: false,
      }
    case FUND_SOURCE_FAILED:
      return {
        ...state,
        isFetching: false,
        data: [],
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

export default fundSource

import axios from './config'

export const fetchNpsn = (token, param) => {
  return () => {
    const urlPage = param ? `schools/info/${param}` : 'schools'
    return new Promise((resolve, reject) => {
      axios.get(
        urlPage,
        { headers: { 'x-api-key': token } },
      ).then((response) => {
        const { data, status } = response
        if (status === 200) {
          resolve(data)
        }
      }).catch(error => {
        reject(error)
      })
    });
  }
}

export const fetchProvince = (token, param) => {
  return () => {
    const urlPage = param ? `locations/provinces?${param}` : 'locations/provinces'
    return new Promise((resolve, reject) => {
      axios.get(
        urlPage,
        { headers: { 'x-api-key': token } },
      ).then((response) => {
        const { data, status } = response
        if (status === 200) {
          resolve(data.data)
        }
      }).catch(error => {
        reject(error)
      })
    });
  }
}

export const fetchCity = (token, param) => {
  return () => {
    const urlPage = param ? `locations/cities?${param}` : 'locations/cities'
    return new Promise((resolve, reject) => {
      axios.get(
        urlPage,
        { headers: { 'x-api-key': token } },
      ).then((response) => {
        const { data, status } = response
        if (status === 200) {
          resolve(data.data)
        }
      }).catch(error => {
        reject(error)
      })
    });
  }
}

export const fetchEducation = (token, param) => {
  return () => {
    const urlPage = param ? `educations/${param}` : 'educations'
    return new Promise((resolve, reject) => {
      axios.get(
        urlPage,
        { headers: { 'x-api-key': token } },
      ).then((response) => {
        const { data, status } = response
        if (status === 200) {
          resolve(data.data)
        }
      }).catch(error => {
        reject(error)
      })
    });
  }
}

export const fetchFunds = (token, param) => {
  return () => {
    const urlPage = param ? `funds/${param}` : 'funds'
    return new Promise((resolve, reject) => {
      axios.get(
        urlPage,
        { headers: { 'x-api-key': token } },
      ).then((response) => {
        const { data, status } = response
        if (status === 200) {
          resolve(data.data)
        }
      }).catch(error => {
        reject(error)
      })
    });
  }
}

import React from 'react';
import { Form, Input, Button, ConfigProvider } from 'antd';
import { Row, Col } from 'react-bootstrap';    
import { connect } from 'react-redux';
import { fetchNpsn } from '../../actions/AdditionalForm';
import { SearchOutlined } from '@ant-design/icons';
import { fetchProvince, fetchCity } from '../../actions/AdditionalForm';
import CustomizeNotFound from '../CustomizeNotFound';
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
class SatuanPendidikan extends React.Component {
  state = {
    onSearching: false,
    data: {},
    searchVal: '',
    onLoadProvince: true,
    provinceList: [],
    onLoadCity: false,
    isCityReady: true,
    cityList: [],
  }

  componentDidMount(){
    const { editValue, isEdit } = this.props

    this.props.fetchProvince(this.props.auth.id_token || process.env.REACT_APP_API_KEY, 'limit=34')
    .then((result) => {
      this.setState({
        onLoadProvince: false,
        provinceList: result.items
      })
    }).catch((error) => {
      this.setState({
        onLoadProvince: false,
        provinceList: []
      })
    })

    if (isEdit) {
      this.setState({searchVal: editValue?.school_permission?.npsn})
      setTimeout(() => {
        // this.handleChangeProvince(editValue?.school_permission?.kd_prop, isEdit)
        this.onSearch()
      },300)
    }
  }

  onChange = (e) => {
    this.setState({
      searchVal: e.target.value
    })
  }

  onSearch = () => {
    this.setState({
      onSearching: true
    })

    this.props.fetchNpsn(this.props.auth.id_token || process.env.REACT_APP_API_KEY, this.state.searchVal)
    .then((result) => {
      if (result.success && result.data) {
        this.setState({
          onSearching: false,
          data: result.data
        })
        this.props.passValue(result.data)
      } else {
        this.setState({
          onSearching: false,
          data: {}
        })
      }
    }).catch((error) => {
      this.setState({
        onSearching: false,
        data: {}
      })
    })
  }

  /* handleChangeProvince = (value, isEdit) => {
    const { editValue } = this.props
    let newProvinceVal

    let selectedCity = isEdit !== true && this.props.formIdentity.current.getFieldValue('city')
  
    if (selectedCity) {
      this.props.formIdentity.current.setFieldsValue({
        city: undefined,
      })
    }

    if (isEdit === true) {
      newProvinceVal = (this.state.provinceList || []).find(item => item.kd_prop === value)
      this.props.formIdentity.current.setFieldsValue({ province: newProvinceVal?.id })
    } else {
      newProvinceVal = (this.state.provinceList || []).find(item => item.id === value)
    }

    if (newProvinceVal) {
      newProvinceVal = {
        id: newProvinceVal.id,
        kd_prop: newProvinceVal.kd_prop
      }
    }
    
    const limit = '514'
    this.setState({
      selectedProvince: value,
      onLoadCity: true,
      isCityReady: true,
    })
    this.props.sendProvince(newProvinceVal)

    this.props.fetchCity(this.props.auth.id_token || process.env.REACT_APP_API_KEY, `limit=${limit}&q=province_id=${isEdit === true ? newProvinceVal?.id : value}`)
    .then((result) => {      
      this.setState({
        onLoadCity: false,
        isCityReady: false,
        cityList: result.items
      })
      if(isEdit === true){
        this.handleChangeCity(editValue?.school_permission?.kd_kab_kota, isEdit)
      }
    }).catch((error) => {
      this.setState({
        onLoadCity: false,
        isCityReady: false,
        cityList: []
      })
    }) 
  } */

  /* handleChangeCity = (value, isEdit) => {
    let newCityVal

    if(isEdit === true){
      newCityVal = (this.state.cityList || []).find(item => item.kd_kab_kota === value)
      this.props.formIdentity.current.setFieldsValue({ city: newCityVal?.id })
    } else {
      newCityVal = (this.state.cityList || []).find(item => item.id === value)
    }

    if (newCityVal) {
      newCityVal = {
        id: newCityVal.id,
        kd_kabkota: newCityVal.kd_kab_kota
      }
    }
    this.props.sendCity(newCityVal) //send value to parent
  } */

  render(){
    const {
      onSearching, searchVal, data, /* provinceList,
      onLoadProvince, cityList, onLoadCity, isCityReady, */
    } = this.state

    return(
      <React.Fragment>
        <ConfigProvider renderEmpty={CustomizeNotFound}>
          {/* <Form.Item
            label="Wilayah"
            name="province"
            rules={[{ required: true, message: "Wajib diisi" }]}
          >
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder="Pilih provinsi"
              onChange={this.handleChangeProvince}
              loading={onLoadProvince}
              disabled={onLoadProvince}
            >
              {(provinceList || []).map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
            </Select>
          </Form.Item> */}
          {/* <Form.Item
            label="Kota"
            name="city"
            rules={[{ required: true, message: "Wajib diisi" }]}
          >
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder="Pilih kabupaten/kota"
              onChange={this.handleChangeCity}
              disabled={isCityReady}
              loading={onLoadCity}
            >
              {(cityList || []).map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
            </Select>
          </Form.Item> */}
          <Form.Item 
            label="NPSN"
            name="npsn"
            rules={[{ required: true, message: "Wajib diisi" }]}
          >
            <div className="d-flex">
              <Input
                placeholder="Masukkan NPSN"
                onChange={this.onChange}
                disabled={onSearching}
                value={searchVal}
                onPressEnter={this.onSearch}
              />
              <Button type="primary" disabled={searchVal ? false : true} className="ml-3" onClick={this.onSearch} loading={onSearching} icon={<SearchOutlined />}/>
            </div>
          </Form.Item>
          <Form.Item
            {...tailFormItemLayout}
            name="detail"
          >
            <Row>
              <Col xs={4}>
                <p>Nama Sekolah</p>
              </Col>
              <Col xs={8}>
                <p><span className="mr-2">:</span>{data.name || '-'}</p>
              </Col>
              <Col xs={4}>
                <p>NPSN</p>
              </Col>
              <Col xs={8}>
                <p><span className="mr-2">:</span>{data.npsn || '-'}</p>
              </Col>
              <Col xs={4}>
                <p>Email</p>
              </Col>
              <Col xs={8}>
                <p><span className="mr-2">:</span>{data.email || '-'}</p>
              </Col>
              <Col xs={4}>
                <p>Website</p>
              </Col>
              <Col xs={8}>
                <p><span className="mr-2">:</span>{data.website || '-'}</p>
              </Col>
              <Col xs={4}>
                <p>No. Telepon</p>
              </Col>
              <Col xs={8}>
                <p><span className="mr-2">:</span>{data.nomor_telepon || '-'}</p>
              </Col>
              <Col xs={4}>
                <p>No. Fax</p>
              </Col>
              <Col xs={8}>
                <p><span className="mr-2">:</span>{data.nomor_fax || '-'}</p>
              </Col>
              <Col xs={4}>
                <p>Alamat</p>
              </Col>
              <Col xs={8}>
                <p><span className="mr-2">:</span>{data.alamat_jalan || '-'}</p>
              </Col>
              <Col xs={4}>
                <p>Rt/Rw</p>
              </Col>
              <Col xs={8}>
                <p><span className="mr-2">:</span>{data.rt || '-'}/{data.rw || '-'}</p>
              </Col>
              <Col xs={4}>
                <p>Kelurahan</p>
              </Col>
              <Col xs={8}>
                <p><span className="mr-2">:</span>{data.desa_kelurahan || '-'}</p>
              </Col>
              <Col xs={4}>
                <p>Kecamatan</p>
              </Col>
              <Col xs={8}>
                <p><span className="mr-2">:</span>{data.kecamatan || '-'}</p>
              </Col>
              <Col xs={4}>
                <p>Kabupaten/kota</p>
              </Col>
              <Col xs={8}>
                <p><span className="mr-2">:</span>{data.kabupaten || '-'}</p>
              </Col>
              <Col xs={4}>
                <p>Provinsi</p>
              </Col>
              <Col xs={8}>
                <p><span className="mr-2">:</span>{data.provinsi || '-'}</p>
              </Col>
              <Col xs={4}>
                <p>Kode Pos</p>
              </Col>
              <Col xs={8}>
                <p><span className="mr-2">:</span>{data.kodepos || '-'}</p>
              </Col>
            </Row>
          </Form.Item>
        </ConfigProvider>
      </React.Fragment>
    )
  }
}

export default connect(
  ({auth}) => ({ auth }),
  { fetchNpsn, fetchProvince, fetchCity }
)(SatuanPendidikan)
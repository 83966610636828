import React from 'react';
import { connect } from 'react-redux';
/*import { withRouter } from 'react-router-dom';*/
/*import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css'*/
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import { Form, Input, Statistic, message, Modal } from 'antd';
import { ssoLogin, otpRequest, resendOtp } from '../../actions/Auth';
import { forgotPassword } from '../../actions/Password'
import OtpInput from 'react-otp-input';
import Swal from 'sweetalert2'

class LoginSso extends React.Component {
  formRef = React.createRef();

  state = {
    isSubmit: false,
    clientId: '',
    toggleModal: false,
    otpVal: '',
    user_id: undefined,
    isCountDownFinished: false,
    countDownTime: null,
    isLoginSso: true,
  }

  componentDidMount(){
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.setState({
      clientId: params.client_id,
      // countDownTime: 300000,
    })
  }

  handleOtpModal = () => {
    this.setState({
      countDownTime: Date.now()+300000,
      toggleModal: !this.state.toggleModal,
    })
  }

  handleChangeOtp = (val) => {
    this.setState({
      otpVal: val,
    })
  }

  handleResendOtp = () => {
    this.props.resendOtp({user_id: this.state.user_id})
    .then((result) => {
      message.success('Kode telah dikirim kembali ke email anda')
      this.setState({
        isCountDownFinished: false,
        countDownTime: Date.now()+300000,
      })
    })
    .catch(error => {
      if (error.response) {
        console.log(error?.response?.data?.error)
      }
    })
  }

  handleSubmitOtp = () => {
    const payload = {
      user_id: this.state.user_id,
      client_id: this.state.clientId,
      otp: this.state.otpVal,
    }

    this.props.otpRequest(payload)
    .then((result) => {
      if (result.success) {
        this.setState({
          user_id: undefined,
          clientId: '',
          otpVal: '',
        })
      }
    }).catch(error => {
      if (error.response) {
        console.log(error?.response?.data?.error)
      }
    })
  }

  render() {
    const { isSubmit, countDownTime } = this.state
    // const { auth } = this.props

    const onFinishCountDown = (val) => {
      this.setState({
        isCountDownFinished: true
      })
    }

    const onFinish = (values) => {
      this.setState({
        isSubmit: true
      })
      const payload = {
        ...values,
        client_id: this.state.clientId
      }
      this.props.ssoLogin(payload)
      .then((result) => {
        this.setState({
          isSubmit: false,
          user_id: result.data.id,
        })
        if (result.success) {
          this.handleOtpModal()
        }
      }).catch(error => {
        if (error?.response && error?.response?.data) {
          console.log(error.response.data.error)
        }
        this.setState({
          isSubmit: false
        })
      })
    };

    const onFinishForgot = (values) => {
      this.setState({isSubmit: true})
      this.props.forgotPassword(values)
      .then((result) => {
        this.setState({isSubmit: false})
        this.formRef.current.resetFields()
        Swal.fire(
          'Link Reset Password sudah dikirim',
          'Silahkan cek Email Anda',
          'success',
        ).then(() => {
          this.setState({isLoginSso: true})
        })
      }).catch(error => {
        if (error?.response && error?.response?.data) {
          console.log(error.response.data.error)
        } else {
          console.log(error)
        }
         this.setState({
          isSubmit: false
        })
      })
    }

    const handleSwitchLogin = () => {
      const { isLoginSso } = this.state

      this.setState({isLoginSso: !isLoginSso})
      this.formRef.current.resetFields()
    }

    return (
      <React.Fragment>
        <div className="h-100">
          <div className="d-flex align-items-center flex-column justify-content-center h-100">
            <Container>
              <Row className="justify-content-center">
                <Col xs="12" md="8" lg="4" className="mb-3">
                  <Card className="card-login login-pg">
                    {this.state.isLoginSso ? (
                      <Card.Body>
                        <div className="mb-5 text-center">
                          <h1 className="mb-0">SSO</h1>
                          <small>Single Sign-On</small>
                        </div>
                        <Form
                          name="basic"
                          onFinish={onFinish}
                          ref={this.formRef}
                        >
                          <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Email harus diisi' }]}
                          >
                            <Input placeholder="Email"/>
                          </Form.Item>

                          <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Password atau kata sandi harus diisi' }]}
                          >
                            <Input.Password placeholder="Kata Sandi"/>
                          </Form.Item>

                          <Form.Item className="text-center">
                            <Button className="btn-block mt-4 custom-primary" variant="primary" type="submit">{isSubmit ? 'Loading...' : 'MASUK'}</Button>
                            <Button variant="link" className="px-0" onClick={handleSwitchLogin}>Lupa password</Button>
                          </Form.Item>
                        </Form>
                      </Card.Body>
                    ) : (
                      <Card.Body>
                        <div className="mb-2 text-center">
                          <h5>Lupa Password</h5>
                          <h6>Masukan email yang digunakan</h6>
                        </div>
                        <div className="text-center py-3">
                          <img src="/Assets/img/logo.png" style={{width: '120px', heigh: 'auto'}} alt=""/>
                        </div>
                        <Form 
                          name="forgot"
                          onFinish={onFinishForgot}
                          ref={this.formRef}
                        >
                          <div className="card-login">
                            <Form.Item
                              name="email"
                              rules={[{ required: true, message: 'Email harus diisi' }]}
                            >
                              <Input placeholder="Email"/>
                            </Form.Item>
                            <Form.Item className="text-center">
                              <Button className="btn-block mt-4 custom-primary" variant="primary" type="submit">{isSubmit ? 'Loading...' : 'Submit'}</Button>
                              <Button variant="link" className="px-0" onClick={handleSwitchLogin}>SSO (Single Sign-On)</Button>
                            </Form.Item>
                          </div>
                        </Form>
                      </Card.Body>
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Modal
          centered
          maskClosable={false}
          visible={this.state.toggleModal}
          footer={null}
          closable={false}
        >
          <div className="p-3 my-3">
            <h5 className="text-center pb-3">Masukan kode OTP yang dikirim ke Email anda</h5>
            <OtpInput
              value={this.state.otpVal}
              onChange={this.handleChangeOtp}
              numInputs={6}
              containerStyle="justify-content-center"
              inputStyle="form-control w-otp"
              separator={<span className="mx-2">.</span>}
            />
            <div className="text-center pt-3">
              <span>Tunggu beberapa saat untuk mengirimkan kembali ke email anda</span>
              {!this.state.isCountDownFinished && (
                <Statistic.Countdown value={countDownTime} onFinish={onFinishCountDown}></Statistic.Countdown>
              )}
            </div>
            <div className="pt-3 pb-2">
              {this.state.isCountDownFinished && (
                <Button className="float-left" variant="primary" onClick={() => this.handleResendOtp()}>Kirim Ulang OTP</Button>
              )}
              <Button
                className="custom-primary float-right"
                variant="primary"
                onClick={() => this.handleSubmitOtp()}
              >
                Verify OTP
              </Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default connect(
  ({ auth }) => ({ auth }),
  { ssoLogin, otpRequest, resendOtp, forgotPassword }
)(LoginSso)
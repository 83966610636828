const initialState = {
  auth: {
    loading: false,
    isAuthenticated: false,
    id: null,
    name: null,
    roles: [],
  },
  site: {
    titlePage: 'Dasboard',
    activePage: 'dashboard',
    activeSubPage: 'dashboard',
  },
  schoolProvince: {},
  statisticTransaction: {},
  educationStatistic: {},
  fundSource:{},
  users:{},
  schoolDistrict:{},
  detailSchool:{}
}

export default initialState
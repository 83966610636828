import React from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap';

const Wraper = styled.div`
  display:flex;
  flex:1;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px 0;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const Loader = () => {
  return(
    <Wraper>
      <Spinner animation="border" variant="primary" />
      <span className="sr-only">Loading...</span>
    </Wraper>
  )
}

export default Loader

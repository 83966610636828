import initialState from './initialState'
import {
  STATISTIC_REQUEST,
  STATISTIC_MARKETPLACE_SUCCESS,
  STATISTIC_TRANSACTION_FAILED,
} from '../actions/constans'

const statisticTransaction = (state = initialState.statisticTransaction, action = {}) => {
  switch (action.type) {
    case STATISTIC_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case STATISTIC_MARKETPLACE_SUCCESS:
      return {
        ...state,
        ...action.data,
        isFetching: false,
      }
    case STATISTIC_TRANSACTION_FAILED:
      return {
        ...state,
        isFetching: false,
        data: [],
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

export default statisticTransaction

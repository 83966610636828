import initialState from './initialState'
import { 
  AUTH_REQUEST, 
  AUTH_RECEIVED, 
  AUTH_FAILED, 
  AUTH_SIGNOUT,
} from '../actions/constans'

const auth = (state = initialState.auth, action = {}) => {
  switch (action.type) {
    case AUTH_REQUEST:
      return { ...state, loading: true }
    case AUTH_RECEIVED: {
      return {
        ...state,
        ...action.data,
        loading: false,
        isAuthenticated: true,
      }
    }
    case AUTH_FAILED:
      return { ...state, loading: false, error: action.error }
    case AUTH_SIGNOUT:
      return { ...initialState.auth }
    default: return state
  }
}

export default auth

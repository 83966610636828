import React from 'react'
import { Result, Button } from 'antd';

class page404 extends React.Component {
  state = {
    
  }

  render() {

    return (
      <div>
        <Result
          status="404"
          title="404"
          subTitle="Maaf, kamu tidak memiliki hak akses atau halaman tidak ada"
          extra={<Button type="primary" href="/">Kembali ke Beranda</Button>}
        />,
      </div>
    )
  }
}

export default page404
import Sdk from "casdoor-js-sdk";

export const ServerUrl = process.env.REACT_APP_STAGING_API_URL;
export const CasdoorUrl = process.env.REACT_APP_CASDOOR_URL;
export const ClientId = process.env.REACT_APP_CASDOOR_CLIENT_ID;

const sdkConfig = {
  serverUrl: CasdoorUrl,
  clientId: ClientId,
  appName: "siplah-dashboard-pengawas",
  organizationName: "sds",
  redirectPath: "/callback",
  signinPath: "/signin",
};

export const CasdoorSDK = new Sdk(sdkConfig);
